import type { QueryResult } from '@apollo/client/react/types/types';
import React, { useId, useRef, useState } from 'react';
import { Form, Modal, useClassNameMapper } from 'react-bootstrap';
import { useDebounce } from 'react-use';
import type { UseDebounceReturn } from 'react-use/lib/useDebounce';
import { SharedComponent } from '../../../../enums';
import useQueryWithTracing from '../../../useQueryWithTracing';
import useTranslation from '../../../useTranslation';
import Button from '../../Button/Button';
import { ButtonVariant } from '../../Button/enums';
import { PagerPosition } from '../enums';
import type { PagerSeeAllModalProps } from '../types';

/**
 *  The `seeAllModal` variant for `Pager`. Displays links that open a modal with more results.
 *
 * @author Adam Ayres, Jonathan Bridges
 */
const PagerSeeAllModal = <TData, TVariables>({
  pageInfo,
  position = PagerPosition.START,
  modalClassName,
  modalTitle: ModalTitle,
  modalSize,
  modalContent,
  children = null,
  className,
  queryAndOptions,
  useSearchField = false,
  searchFieldPlaceholder
}: PagerSeeAllModalProps<TData, TVariables>): React.ReactElement => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.PAGER_SEE_ALL_MODAL
  );
  const usePaging = pageInfo.hasNextPage || pageInfo.hasPreviousPage;

  const [showModal, setShowModal] = useState<boolean>(false);
  const modalBodyReference = useRef(null);

  const [searchText, setSearchText] = useState<string>();
  const [searchQueryVariables, setSearchQueryVariables] = useState<TVariables>();

  const handleClose = (): void => {
    setShowModal(false);
    setSearchText('');
  };
  const handleShow = (): void => setShowModal(true);
  const uid = useId();

  const [isDebounceReady]: UseDebounceReturn = useDebounce(
    () => {
      if (searchText) {
        setSearchQueryVariables({
          ...queryAndOptions.searchQueryVariables(searchText)
        });
      } else {
        setSearchQueryVariables(null);
      }
    },
    500,
    [searchText]
  );

  const queryResults = useQueryWithTracing<TData, TVariables>(module, queryAndOptions.query, {
    variables: {
      ...queryAndOptions.options.variables,
      ...searchQueryVariables
    },
    skip: !showModal
  });

  if (!usePaging || textLoading) {
    return null;
  }

  // alert the QueryHandler component used by PaneledEntityList to display a loading indicator when debounce is not ready
  const adjustedQueryResult: QueryResult<TData, TVariables> = {
    ...queryResults,
    loading: !isDebounceReady() || queryResults?.loading,
    ...(!isDebounceReady() && { data: null })
  };

  /**
   * A callback to handle search filter
   */
  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value: text } = event.target;
    setSearchText(text);
  }

  /**
   * A callback function to render search filter
   */
  function renderSearchField(): React.ReactElement {
    return (
      <Form.Control
        type="text"
        onChange={handleSearchFilter}
        placeholder={searchFieldPlaceholder}
        className={cx('lia-g-mt-20 lia-g-subtext-modal-subtext')}
      />
    );
  }

  function wrapper(wrapped: React.ReactElement): React.ReactElement {
    return (
      <div className={cx(className, `d-flex w-100 justify-content-${position}`)}>{wrapped}</div>
    );
  }

  // Use wrapper when there are children.
  const hasWrapper = !children;

  const pager = (
    <>
      <Button
        onClick={handleShow}
        variant={ButtonVariant.LINK}
        className={cx({ [className]: !hasWrapper && className }, { 'lia-g-loader-btn': !children })}
        data-testid="PagerSeeAllModal.Button"
      >
        {children || <>{formatMessage('seeAllLink')}</>}
      </Button>
      {showModal && (
        <Modal
          className={cx('lia-g-infinite-scroll-modal')}
          show={showModal}
          onHide={handleClose}
          size={modalSize}
          scrollable
          centered
          aria-labelledby={uid}
          data-testid="PagerSeeAllModal"
        >
          <Modal.Header className={cx('lia-g-subtext-modal-header')} closeButton>
            <Modal.Title
              className={cx('lia-g-subtext-modal-title')}
              id={uid}
              data-testid="PagerModalTitle"
            >
              <ModalTitle />
            </Modal.Title>
            {useSearchField && renderSearchField()}
          </Modal.Header>
          <Modal.Body className={cx(modalClassName)} ref={modalBodyReference}>
            {modalContent(adjustedQueryResult, modalBodyReference)}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
  return hasWrapper ? wrapper(pager) : pager;
};

export default PagerSeeAllModal;
